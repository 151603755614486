.sdt-page {
    @include media-breakpoint-up(md) {
        .container {
            max-width: breakpoint-max(md) !important;
        }
    }

    section {
        padding-top: spacer(head);
        padding-bottom: spacer(head);
    }

    .marquee-header {
        background-color: $teal;
        &:before {
            content: "";
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            opacity: 0.2;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: 80% 115%;
        }
    }

    .offer-cards {
        div.card {
            padding-top: spacer(4);
            padding-bottom: spacer(4);
            height: 100%;
            border-width: $border-width * 3;

            h4 {
                font-weight: 500;
                margin: 0;
            }
            p {
                margin: spacer(3) 0 0 0;
            }

        }
    }
}

.sdt-self-directed-trading {

    section.top-index {
        h1 {
            font-size: $h2-font-size;
        }

        .top-image {
            display: flex;
            align-items: flex-end;

        }
        .top-text {
            background: $white;
            .btn {
                width: 150px;
            }
        }

        @include media-breakpoint-down(sm) {
            .top-image {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;
                    background: linear-gradient(187deg, rgba(lighten($teal, 15%), 1) 0% 30%, rgba(0, 0, 0, 0) 30%),
                    linear-gradient(193deg, rgba(lighten($teal, 7%), 1) 46%, rgba(lighten($teal, 0%), 1) 46%);
                }
            }

        }
        @include media-breakpoint-up(md) {
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 1) 61%),
            linear-gradient(187deg, rgba(lighten($teal, 15%), 1) 0% 20%, rgba(0, 0, 0, 0) 20%),
            linear-gradient(193deg, rgba(lighten($teal, 7%), 1) 31%, rgba(lighten($teal, 0%), 1) 31%);
        }
    }

    section.simple {
        @include media-breakpoint-between(md, md) {
            img {
                max-width: 110%;
            }
        }
    }

    section.pricing {
    }

}

.sdt-stocks {
    .marquee-header {
        &:before {
            background-image: url(/assets/public/images/icons/pie-chart.svg);
        }
    }

    section.offers {
        .row {
            justify-content: center;
            .col {
                flex: 0 0 255px;
                padding: $grid-gutter-width / 2;
                min-width: 180px;

                @include media-breakpoint-down(sm) {
                    flex-basis: 50%;
                }
                @include media-breakpoint-down(xxs) {
                    flex-grow: 1;
                }
            }
        }
        .offer-cards {
            .card {
                color: $body-color;
            }
        }
    }

    section.why-investors {
        h6 {
            font-weight: 600;
        }
    }
}

.sdt-options {
    .marquee-header {
        &:before {
            background-image: url(/assets/public/images/icons/options.svg);
        }
    }

    section.leverage {
        overflow: hidden;

        div.image {

            img {
                max-width: none;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(sm) {
            div.image {

                img {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100vw;
                    min-height: 200px;
                    max-height: 250px;
                    max-width: none;
                    object-fit: cover;
                }
            }
        }
        @include media-breakpoint-up(md) {
            div.image {
                position: absolute;
                top: -1px; bottom: 0; left: 0; right: 0;

                img {
                    height: 100%;
                    width: 50vw;
                }
            }
        }

    }

    section.offers {
        .offer-cards {
            .col {
                flex: 0 0 270px;
                //width: 270px;
                //min-width: 270px;
                //max-width: 270px;
                margin-bottom: spacer(4);
            }
            margin-bottom: -1 * spacer(4);
        }
    }
}

.sdt-margin {
    .marquee-header {
        &:before {
            background-image: url(/assets/public/images/icons/margin.svg);
        }
    }

    section.leverage {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            background-image: url(/assets/public/images/mp/scale.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.6;
            filter: brightness(0.8);
        }
    }

    section.tables {
        ul {
            li {
                margin-bottom: spacer(3);
            }
        }
        .aurea-accordion {
            .card {
                border-width: 1px;
                border-radius: $border-radius;
            }
        }
        table {
            margin: 0;

            .label-up {
                padding-top: spacer(4);
            }

            tr {
                td:nth-child(2) {
                    border: 1px solid $border-color;
                }
            }
            tr:last-child {
                td:nth-child(2) {
                    border-bottom-width: 0;
                }
            }
        }
    }
}

.sdt-fully-paid-lending {
    .marquee-header {
        &:before {
            background-image: url(/assets/public/images/icons/hand-coins.svg);
        }
    }
}


