.site-index-page {

    @include media-breakpoint-up(md) {
        .container {
            max-width: breakpoint-max(md) !important;
        }
    }

    h1 {
        font-size: $h2-font-size;
    }

    section.top-index {

        .top-image {
            display: flex;
            align-items: flex-end;

        }
        .top-text {
            background: $white;

            a {
                .icon-round-blue, .icon-round-green {
                    height: 65px;
                    width: 65px;
                }
                &:hover, &:focus {
                    div {
                        opacity: 0.8;
                    }
                }
            }
       }

        @include media-breakpoint-down(sm) {
            .top-image {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;
                    background: linear-gradient(187deg, rgba(lighten($teal, 15%), 1) 0% 30%, rgba(0, 0, 0, 0) 30%),
                    linear-gradient(193deg, rgba(lighten($teal, 7%), 1) 46%, rgba(lighten($teal, 0%), 1) 46%);
                }
            }

        }
        @include media-breakpoint-up(md) {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 1) 61%),
            linear-gradient(187deg, rgba(lighten($teal, 15%), 1) 0% 30%, rgba(0, 0, 0, 0) 30%),
            linear-gradient(193deg, rgba(lighten($teal, 7%), 1) 46%, rgba(lighten($teal, 0%), 1) 46%);
        }

    }

    section.bank-security {
        position: relative;
        background-color: lighten($teal, 20%);

        &:before {
            content: "";
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            background-image: url(/assets/public/images/mp/vault.svg);
            background-position: center center;
            background-repeat: no-repeat;
            opacity: 0.4;
            filter: brightness(1.3)  hue-rotate(330deg) saturate(0.7);
        }
    }

    section.empowering {
        .card {
            height: 100%;
            background: transparent;

            &.card-sdt-index {
                .card-header {
                    background: rgba($teal, 0.4);
                }
                @include media-breakpoint-up(md) {
                    .card-header {
                        padding-bottom: 2.5rem;
                    }
                }
            }

            &.card-mp-index {
                .card-header {
                    background: rgba($primary, 0.4);
                }
            }


            .card-body {
                padding-bottom: 0;

                p {
                    margin-bottom: spacer(3);
                }
            }

            .card-footer {
                background: transparent;
                border: none;
                padding-top: 0;

                .btn {
                    width: 200px;
                }
            }
        }
    }
}

.site-about-page {
    .graphic {
        &.map {
            display: flex;
            align-items: center;
            justify-content: center;

            white-space: nowrap;
            opacity: .2;
            img {
                height: 135%;
                animation: animate-map 30s linear infinite;
            }
        }

        @keyframes animate-map {
            from {
                transform: translateX(-50%);
            }
            to {
                transform: translateX(0%);
            }
        }
    }
}

.site-press-page {
    .graphic {

        &.matrix {
            background: url(/assets/public/images/main-pages/matrix-ipsum.svg);
            background-size: auto 225px;
            animation: animate-matrix 0.5s linear infinite;
        }

        @keyframes animate-matrix {
            from {
                background-position-y: 0;
            }
            to {
                background-position-y: 225px;
            }
        }
    }

    section.news-logos {
        .news-logo {
            flex: 1 0 33.333%;
            padding: spacer(4);
        }
    }

}
