div[code-input] {
    .code-inputs-boxes {

        display: flex;
        justify-content: center;

        input {
            max-width: 3.5rem;
            height: auto;
            padding: 0.3rem 0;
            margin: 0 spacer(2) 0 spacer(2);

            border-radius: $border-radius;
            font-size: 2rem;
            text-align: center;


            //color: transparent;
            //text-shadow: 0 0 0 $body-color;

            &:focus {
                & + span {
                    display: inline-block;
                }
            }

            &:hover {
                border-color: $border-color;
                background: transparent;
            }

            @include media-breakpoint-down(xxs) {
                margin: 0 spacer(1) 0 spacer(1);
            }

        }
    }
}
