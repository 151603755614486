.browser-update-page {
    .why-update-box {
        h5 {
            font-weight: 400;
        }
    }
    .browser-update-box {
        display: flex;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        .browser {
            flex-basis: 20%;
            transition: flex-basis 300ms ease-in-out;
            padding: 6vh spacer(1);

            color: $white;
            text-align: center;

            .statistic, .available, .center .download .btn {
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }
            .statistic {
                line-height: 1.2rem;
                h1 {
                    font-weight: 600;
                    line-height: 2rem;
                }
            }
            .center {
                margin: 6vh 0;
                h2 {
                    font-size: small;
                    font-weight: 600;
                    text-transform: uppercase;
                    img {
                        display: block;
                        margin: auto;
                        margin-bottom: spacer(3);
                        max-width: 90px;
                        filter: drop-shadow( 0px 0px 0px rgba(255, 255, 255, 0))
                        drop-shadow( 0px 0px 0px rgba(255, 255, 255, 0))
                        saturate(0) brightness(1.1) contrast(1.3);

                        transition: max-width 300ms ease-in-out, filter 300ms ease-in-out;
                    }
                }
                .download {
                    .btn {
                        border-color: $white;
                        color: $white;
                        border-radius: 50px;
                        &:hover, &:focus {
                            opacity: 1;
                            background-color: rgba(#000, 0.1);
                        }
                    }
                }
            }
            .available {
                h5 {
                    font-size: smaller;
                }
                ul {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    list-style: none;
                    img {
                        padding: 5px;
                        height: 30px;
                        width: 30px;
                    }
                }
            }

            &:hover {
                flex-basis: 35%;
                .statistic, .available, .center .download .btn {
                    opacity: 1;
                }
                .center {
                    h2 {
                        img {
                            //max-width: 125px;
                            filter: drop-shadow( 2px 2px 18px rgba(255, 255, 255, 1))
                            drop-shadow( 2px 2px 18px rgba(255, 255, 255, 1));
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                padding: 3vh spacer(1);
                .statistic, .available, .center .download .btn {
                    opacity: 1;
                }
                .center {
                    margin: 3vh 0;
                }
            }



            &#chrome {
                background-color: #f2b635;
            }
            &#firefox {
                background-color: #f19a2a;
            }
            &#edge {
                background-color: #00caff;
            }
            &#safari {
                background-color: #00a0e6;
            }
            &#opera {
                background-color: #f25648;
            }


        }
    }
}
