@charset "UTF-8";

/**
 * Note: Upon updating this, make sure to replace icon classes named .icon- with .wbi-
 *
 * Also note: preferred method of use is class name rather than data-icon mapping since icon name is more clear
 * And use http://jsfiddle.net/phazei/8fc8egz8/5/ to replace fontastic.me hex values with ascii
 */

@font-face {
    font-family: 'wisebanyan-icon-font';
    src: url($wb-font-path + 'wbi-new.eot');
    src: url($wb-font-path + 'wbi-new.eot?#iefix') format('embedded-opentype'),
    url($wb-font-path + 'wbi-new.woff') format('woff'),
    url($wb-font-path + 'wbi-new.ttf') format('truetype'),
    url($wb-font-path + 'wbi-new.svg#wbi-new') format('svg');
    font-weight: normal;
    font-style: normal;
}


.wbi, %wbi {
    display: inline-block;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    font-family: "wisebanyan-icon-font"; //outer font must match inner or peculiar line height issues ensue
    line-height: 1;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.wbi:before, %wbi:before,
[data-icon]:before,
[class^="wbi-"]:before,
[class*=" wbi-"]:before {
    font-family: 'wisebanyan-icon-font' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wbi-wisebanyan-logo:before, %wbi-wisebanyan-logo:before {
    content: "\77";
}
.wbi-check:before, %wbi-check:before {
    content: "\6d";
}
.wbi-gift:before, %wbi-gift:before {
    content: "\67";
}
.wbi-lock:before, %wbi-lock:before {
    content: "\6c";
}
.wbi-unlock:before, %wbi-unlock:before {
    content: "\4c";
}
.wbi-spinner:before, %wbi-spinner:before {
    content: "\73";
}
.wbi-bell:before, %wbi-bell:before {
    content: "\62";
}
.wbi-eye-open:before, %wbi-eye-open:before {
    content: "\65";
}
.wbi-eye-close:before, %wbi-eye-close:before {
    content: "\45";
}
.wbi-plus:before, %wbi-plus:before {
    content: "\2b";
}
.wbi-reload-icon:before, %wbi-reload-icon:before {
    content: "\72";
}
.wbi-twitter:before, %wbi-twitter:before {
    content: "\63";
}
.wbi-instagram:before, %wbi-instagram:before {
    content: "\64";
}
.wbi-facebook:before, %wbi-facebook:before {
    content: "\66";
}
.wbi-linkedin:before, %wbi-linkedin:before {
    content: "\61";
}
.wbi-cog:before, %wbi-cog:before {
    content: "\68";
}
.wbi-info:before, %wbi-info:before {
    content: "\69";
}
.wbi-envelope:before, %wbi-envelope:before {
    content: "\70";
}
.wbi-trash:before, %wbi-trash:before {
    content: "\74";
}
.wbi-arrow-left:before, %wbi-arrow-left:before {
    content: "\2190";
}
.wbi-arrow-right:before, %wbi-arrow-right:before {
    content: "\2192";
}
.wbi-chevron-right:before, %wbi-chevron-right:before {
    content: "\3e";
}
.wbi-close:before, %wbi-close:before {
    content: "\78";
}

/**
 * 
 * Add additional animations to icons
 * 
 */

.wbi-spin, %wbi-spin {
    animation: spinCW 2s infinite linear;
    vertical-align: middle;
}

.wbi-rotate-90, %wbi-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.wbi-rotate-180, %wbi-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.wbi-rotate-270, %wbi-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}
.wbi-flip-horizontal, %wbi-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.wbi-flip-vertical, %wbi-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -moz-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    transform: scale(1, -1);
}
