/*- Messaging -*/
.messaging {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-tooltip;
    transition: width .5s;
    box-shadow: 0 0 10px #151515;

    .alert-box {
        overflow: hidden;

        transition: max-height 0.5s;
        &.ng-enter {
            max-height: 0;
        }
        &.ng-enter-active {
            max-height: 50px;
        }
        &.ng-leave {
            max-height: 50px;
        }
        &.ng-leave-active {
            max-height: 0;
        }

        .alert {
            border: none;
            border-radius: 0;
            margin-bottom: 0;
            max-height: 200px;

            font-size: 0.9em;

        }
    }
}
