
.reminder-unsubscribe {
    text-align: center;

    .mx-auto {
        max-width: 535px;
    }

    h2 {
        color: $primary;
    }

    margin-top: $header-margin;

    p {
        &.intro {
        }
        &.outro {
            margin-bottom: 20px;
        }
    }

    .reminder {
        max-width: 535px;
        margin:auto;

        h4 {
            margin: 0 0 20px;
        }

        .datepicker {
            //max-width: 300;
            border: 1px solid #EEEEEE;
            border-radius: 5px;
            margin: 30px auto;
            padding: 5px;

            table {
                .uib-day {
                    padding-bottom: 0.5rem;

                    .btn {
                        padding: 0;
                        height: 32px;
                        width: 32px;
                        line-height: 34px;
                        min-width: initial;
                        border-radius: 100px;
                    }
                }

            }
        }
    }
}
