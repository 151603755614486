/**
 * For styling of plugins and tools that will be aurea wide
 */


div[slider] {

    &[class*=" ng-invalid"] {
        .slider {
            .slider-track {
                background: $error-red !important;
            }
            .slider-handle {
                box-shadow: 0 0 2px 0.6px $error-red !important;
            }
        }
    }

    .slider {
        .tooltip {
            &.top {
                margin-top: -48px;
            }
        }

        .slider-handle {
            height: 20px;
            width: 20px;
            border: 5px solid white;
            box-shadow: 0 0 2px 0.6px #666666;
            background: $primary;
            cursor: pointer;

            &:focus {
                outline: none;
                box-shadow: 0 0 2px 1.6px $primary;
            }
        }

        .slider-track {
            background: $light;
            border: 2px solid $card-border-color;

            .slider-tick-container {

            }
            .slider-selection {
                background: transparent;
                box-shadow: none;
            }
        }

        .tooltip-main {
            z-index: $z-index-nav - 5;
        }

    }
}

.aurea-accordion {
    display: block;

    .card:not(.link-header) {
        .card-header {
            a {
                display: block;
                color: $md-gray;
                font-size: $h5-font-size;
                padding: 1.25rem 0;
            }
        }
    }

    .card {
        border-width: 0 0 1px 0;
        border-radius: 0;
        border-color: $gray-200;
        background: transparent;

        .card-header {
            padding: 0;
            border: none;
            background: transparent;

            a {

                .wbi-close {
                    margin-right: 5px;
                    transform: rotate(45deg);
                    transition: transform .5s;
                    font-size: $font-size-base;
                }
            }
        }
        .card-body {
            padding: 0;
        }

        &.card-open {
            .card-header {
                .wbi-close {
                    transform: rotate(0deg);
                }
            }
        }
    }
}



/*- For ng-show -*/
.animate-show {

    &.ng-hide-add, &.ng-hide-remove {
        overflow: hidden;
        -webkit-transition: all linear 0.6s;
        transition: all linear 0.6s;
    }

    /* beginning of show */
    &.ng-hide-remove {
        max-height: 0;
        opacity: 0.3;
    }
    /* end of the show */
    &.ng-hide-remove.ng-hide-remove-active {
        max-height: 500px;
        opacity: 1;
    }

    /* beginning of hide */
    &.ng-hide-add {
        max-height: 500px;
        opacity: 1;
    }
    /* end of hide */
    &.ng-hide-add.ng-hide-add-active {
        max-height: 0;
        opacity: 0.3;
    }
}
.nodelay-show.ng-hide-add.ng-hide-add-active {
    display:none !important;
}

/*- For ng-if -*/
.animate-if {

    &.ng-enter, &.ng-leave {
        overflow:hidden;
        -webkit-transition: all linear 0.6s;
        transition: all linear 0.6s;
    }
    /* beginning of the entry */
    &.ng-enter {
        max-height: 0;
        opacity: 0.3;
    }
    /* end of the entry */
    &.ng-enter.ng-enter-active {
        max-height: 500px;
        opacity: 1;
    }
    /* beginning of the exit */
    &.ng-leave {
        max-height: 500px;
        opacity: 1;
    }
    /* end of the exit */
    &.ng-leave.ng-leave-active {
        max-height: 0;
        opacity: 0.3;
    }
}



tags-input {
    .tags {
        padding:5px;
        border-radius: $input-border-radius;

        &.focused {
            box-shadow: none;
            border: 1px solid $input-focus-border-color;
        }

        .input {
            width:100% !important;
            height: $input-height - 15;
            font-family: $font-family-base;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
        }

        .tag-item {
            height: auto;
            padding: 2px 8px 1px 10px;
            margin-right: 5px;

            font-family: $font-family-sans-serif;
            font-size: $font-size-base;
            font-weight: $font-weight-base;

            color: inherit;
            background: $gray-100;
            border: 1px solid $md-gray;
            border-radius: $input-border-radius;

            &.selected {
                background: $primary;
                border: 1px solid $input-focus-border-color;
            }

            .remove-button {
                position: relative;
            }
        }
    }

    &.ng-invalid {
        .tags {
            box-shadow: none;
            border-color: $error-red;
        }
    }
}
