/*******
 *******  Modifications and additions to bootstrap 4
 *******/
@import "bs4-responsive";


@function spacer($key) {
    @return map-get($spacers, $key);
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .flex#{$infix}-center {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            flex-direction: column;
        }
    }
}

$line-height-computed: floor(($font-size-base * $line-height-base));
@media print {
    *,
    *::before,
    *::after {
        color: rgba(0, 0, 0, 1) !important; // Black prints faster
        //background: transparent !important;
    }
}


/*
 */
.progress {
    //since general bs4 shadows are off
    box-shadow: $progress-box-shadow;
}
.progress-bar {
    //progress bar *must* have an initial value or ngStyle won't properly animate width changes
    width: 0;
}

.bg-dark-text-light, .dark-bg {
    //this is already used, should switch to .bg-dark-text-light?
    @include linear-gradient(135deg, #404852 0%, #232a33 100%);
    & {
        color: $gray-100;
    }
}

//make outline buttons show a lighter version of the color
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        &:not([disabled]):not(:disabled):not(.disabled):hover {
            background-color: rgba($value, 0.2);
            color: $value;
        }
    }
}
.btn-link {
    font-weight: $btn-font-weight;
}

.navbar-toggler {
    &:hover, &:focus {
        outline: none;
        //border-color: $primary;
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($primary, .5);
    }
}

.pagination {

    .page-item {

        .page-link {
            @extend .flex-center;
            padding: 0 !important;
            padding-top: 1px !important;
            transition: all 0s;
            border-radius: 50% !important;
            height: 1.875rem;
            width: 1.875rem;
            font-weight: $font-weight-base;
        }
        &.active .page-link {
            //cursor: default;
            &:hover {
                color: $white;
                background-color: $primary;
            }
        }

        &:first-child {
            & > .page-link {
                @extend %wbi-arrow-left;
            }
        }
        &:last-child {
            & > .page-link {
                @extend %wbi-arrow-right;
            }
        }
        &:first-child, &:last-child {
            & > .page-link {
                font-size: 0; //hide content that's there
                @extend %wbi;
                &:before {
                    font-size: $font-size-base;
                }
            }

            &.disabled {
                .page-link {
                }
            }
        }
    }

    &.pagination-sm {
        .page-link {
            padding-top: 2px !important;
            height: 1.6rem;
            width: 1.6rem;
        }
    }
    &.pagination-lg {
        .page-link {
            height: 3rem;
            width: 3rem;
        }
    }
}


.modal {
    .modal-dialog {
        @extend .modal-dialog-centered;
        @media (max-height: breakpoint-max(xxs)) {
            //shorter than min width
            margin: 0.2rem auto;
        }
    }

    &.text-center {
        .modal-footer {
            justify-content: center;
        }
    }


    //animations:

    &.show-add, &.show-remove {
        //show and add so ng-animate gets the timing correct on the animation durations
        transition-duration: $modal-transition-duration !important;
        overflow: hidden;
    }

    .modal-dialog {
        animation-duration: $modal-transition-duration !important;
        transform: none !important; //prevent bs4 default .fade animation
        @extend .animated;
        @extend .fadeInDown;
    }
    &.show-remove {
        .modal-dialog {
            @extend .fadeOutDown;
        }
    }
    &.slide-up-up {
        .modal-dialog {
            @extend .fadeInUp;
        }
        &.show-remove {
            .modal-dialog {
                @extend .fadeOutUp;
            }
        }
    }
}
.modal-backdrop {
    &.show-remove {
        //wait for modal to animate down first and finish at same time
        transition-delay: $modal-transition-duration - 0.3s;
    }
}

.modal-dialog.modal-full {
    margin: 0;
    width: 100vw;
    height:100vh;
    max-width: none;
    .modal-content {
        display: flex;
        justify-content: center;
        height: 100%;
    }

}

.modal-dialog.modal-lg-head {

    .modal-content {
        padding-top: 0;
        .modal-header {
            height: 250px;
            padding: 0;
            margin-bottom: spacer(head) * -1;
            .header-image {
                height: 100%;
                border-radius: $card-border-radius $card-border-radius 0 0;
                img {
                    height: 100%;
                    border-radius: $card-border-radius $card-border-radius 0 0;
                    object-fit: cover;
                }
            }
        }
        .modal-body {
            margin: auto;
            background: $gray-100;
        }
    }
    @include media-breakpoint-up(md) {
        max-width: 90%;
        .modal-body {
            width: 85%;
        }
    }
    @include media-breakpoint-down(sm) {
        margin: auto;
        .modal-body {
            width: 100%;
        }
    }

    .modal-header {
        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: $modal-header-padding / 1.5;
            z-index: 1;
            opacity: 0.5;
            text-shadow: 0px 2px 0px #fff, 0px -1px 0px #fff;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.modal-content {

    &, .modal-header, .modal-body, .modal-footer {
        padding-top: $modal-inner-padding/2;
        padding-bottom: $modal-inner-padding/2;
    }

}

.modal-header {

    & > *:not(.close) {
        flex-grow: 1;
    }

    .close {
        position: absolute;
        padding: 0;
        margin: 0;
        top: $modal-header-padding/2;
        right: $modal-header-padding/2;
        font-size: 1.25rem;
    }

    border-bottom: none;

}
.modal-body {

}
.modal-footer {
    justify-content: flex-start;
    border-top: none;
    text-align: left;
}

[class~=alert] {
    //attribute selector used rather than .alert to overwrite brand color without !important
    color: $white;
    font-weight: 400;

    &.alert-warning, &.alert-light {
        color: $body-color;
    }
}
.close {
    &.white {
        opacity: 1;
        color: white;
        text-shadow: 0 1px 0 $black;
    }
}

[uib-popover] {
    cursor: pointer;
}
.popover {
    font-weight: 400;
    line-height: 1.2;
}


@mixin card-table() {
    tbody {
        //display: flex;
        //flex-wrap: wrap;

        display: grid;
        grid-template-columns: 50% 50%;
        @include media-breakpoint-down(xs) {
            grid-template-columns: 100%;
        }
    }

    thead {
        display: none;
    }

    tr {
        //@extend .card;
        //@extend .list-group;

        margin: $card-deck-margin / 2;
        flex: 1 1 100%;
    }

    td {
        display: block;
        border-color: transparent;

        &:before {
            display: inline-block;
            width: 75px;
            font-weight: $font-weight-bold;
            content: attr(data-title) ' ';
        }

        &:first-child {
            border-bottom: $table-border-width solid $table-border-color;
            background-color: $table-accent-bg;
            color: $gray-500;

            font-weight: $font-weight-bold;
            text-transform: uppercase;
            white-space: nowrap;
        }

        &:last-child {
            //@extend .card-footer;
        }

        &:not(:last-child) {
        }

    }
}
table.table {

    thead {
        th {
            border-top: none;
            border-bottom-width: 1px;

            color: $md-gray;
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: $font-weight-base;
            text-align: left;

            &.sortable {
                line-height: 0.9rem;
                &.sort-desc, &.sort-asc {
                    background: transparent;
                    .sort-indicator {
                        &:before, &:after {
                            margin: 0;
                            bottom: 4.5px;
                        }
                    }
                }
                &.sort-desc {
                    .sort-indicator:after {
                        border-top-width:8px;
                    }
                }
                .sort-indicator {
                    //display: block;
                    &:before, &:after {
                        top: auto;
                        margin: 0;
                        right: 0;
                    }
                    &:before {
                        bottom: 0;
                        border-top-width: 8px;
                    }
                    &:after {
                        bottom: 9px;
                        border-bottom-width: 8px;
                    }
                }
            }
        }
    }

    tfoot {
        &, td {
            border-top: 1px solid $table-border-color;
        }
    }

    & + [ng-table-pagination] {
        .ng-table-pager {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            & > * {
                margin-bottom: spacer(3);
            }

            .ng-table-pagination {
                order: 0;
            }
            .ng-table-counts {
                margin-left: auto;
                order: 1;
                button {
                    @extend .btn-outline-primary;
                    padding: 0;
                    height: 1.875rem;
                    width: 1.875rem
                }
            }
        }
    }

}
table.card-table {
    @include card-table();
    tbody {
        grid-template-columns: 100%;
    }
}

.table-responsive.card-table {
    @include media-breakpoint-down(sm) {
        table {
            @include card-table();
        }
    }
}


.card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;
    .card {
        flex-grow: 1;
        margin-right: $card-deck-margin;
        margin-bottom: $card-deck-margin * 2;
        margin-left: $card-deck-margin;
    }
}

.card:not([class^=bg-]):not([class*= bg-]), .card.bg-transparent {
    border-color: $gray-200;
}


.alert {
    a:not(.btn) {
        color: inherit;
        font-weight: $alert-link-font-weight;
        &:hover {
            text-decoration: underline;
        }
    }

    .close {
        top: 2px;
        opacity: 1 !important;
        text-shadow: none;
        font-size: 1.2rem;
        font-family: "wisebanyan-icon-font";
        &:before {
            @extend %wbi-close;
        }

        &:hover, &:focus {
            color: $white !important;
            opacity: 0.6 !important;
        }

        span:not(.sr-only) {
            display: none;
        }

    }
    &.alert-warning, &.alert-light {
        .close {
            color: $body-color;
            &:hover, &:focus {
                color: $body-color !important;
            }
        }
    }
}




// UI-BOOTSTRAP TWEAKS
[uib-datepicker] {

    table {
        width: 100%;
        thead {

            tr th {
                text-transform: uppercase;
                font-weight: 300;

            }
            tr:first-child th {
                //table header - "< Month Year >"

                padding-bottom: spacer(3);

                .btn {
                    width:100%;
                    padding: 0.5rem 0;
                    border: 0;
                    border-radius: $btn-border-radius;

                    &>span, &>i, &>strong {
                        font-size: larger;
                        font-weight: 300;
                    }
                    text-transform: uppercase;

                    &.uib-left, &.uib-right {
                        color: $md-gray;
                        background: transparent;
                        &:hover, &:focus {
                            background: $gray-200;
                        }
                        i:before {
                            content: "\a0";
                            display: block;
                        }
                    }
                }
            }
            tr:last-child th {
                //table days list "SUN - SAT"
                border:1px solid lighten($lt-gray, 7%);
                border-width: 1px 0;

            }
        }

        tbody {
            tr {

                &:first-child {
                    td {
                        padding-top: spacer(3);
                    }
                }

                .btn {
                    border-radius: 100px;

                    &.active {
                        border-color: $info;
                        &:disabled {
                            opacity: 0.65;
                        }
                    }

                    .text-muted {
                        color: $gray-800 !important;
                    }

                }
            }
        }

        tr {
            button {
                &.btn-info {
                    .text-info {
                        color: $black !important;
                    }
                    .text-muted {
                        color: $gray-200 !important;
                    }
                }
            }
        }
    }



}

