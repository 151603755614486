/********************
 * General CSS for all of home
 ********************
 */
$invite-input-padding: 0.8rem;

[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}

.marquee, .marquee-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    height: calc(150px + 10vw + 10vh);
    max-height: 375px;

    background-color: $primary;

    h1 {
        @extend .container;
        color: $white;
        font-size: calc(1rem + 4vw);
        font-weight: 500;
        text-align: center;
        @include media-breakpoint-up(xl) {
            font-size: 4rem;
        }
        z-index: 1;
    }

    .graphic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}

.mp-invite-form {
    input {
        height: calc(#{$input-line-height * 1em} + #{$invite-input-padding * 2} + #{$input-height-border});
        border-radius: 3rem;
    }
    button {
        padding-top: $invite-input-padding;
        padding-bottom: $invite-input-padding;
        border-radius: 3rem;
    }
    .input-group {
        input {
            border-right: none;
            border-top-right-radius: 15px 3px !important;
            border-bottom-right-radius: 15px 3px !important;
            &.is-invalid {
                background-position: center right 1.5rem;
                padding-right: 2rem;
            }
        }
        .input-group-append {
            margin-left: -1.5rem;
            z-index: 4; //just above input focus z-index
            button {
                border-radius: $border-radius-lg !important;
                &:disabled {
                    //can't be transparent since overlays input
                    opacity: 1;
                    background-color: lighten($primary, 22.5%);
                    border-color: lighten($primary, 22.5%);
                    //background-color: rgbaMorph($primary, #FFF, 0.5);
                }
            }
        }

    }

}

.icon-round-blue, .icon-round-green {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;

    margin: auto;
    margin-bottom: spacer(3);
    height: 100px;
    width: 100px;
    background: $primary;
    border: 6px solid lighten($primary, 10%);
    border-radius: 50%;
}
.icon-round-green {
    background: $teal;
    border-color: lighten($teal, 10%);
}


.btn {
    .wbi-spin {
        float: left;
        font-size: 1.8rem;
        margin: -0.1rem 0.2rem -0.2rem -0.7rem;
    }
}
