.estimate-directive-box {
    position: relative;

    .svg-container, svg {
        width: 100%;
        height: 100%;
    }

    .svg-container svg {
        display: block;
    }

    svg {
        .focus {

            .line {
                fill: none;
                stroke: $gray-800;
                stroke-width: 2.5px;
                stroke-dasharray: 3px;
            }

            .area-0 {
                opacity: 0.3;
            }
        }


        .axis {
            path, line {
                fill: none;
                stroke: rgba(163, 163, 163, 0.3);
                shape-rendering: crispEdges;
            }

        }

        .axis.y {
            display: none;
        }

        .axis.x {

            .tick {
                text {
                    fill: $gray-800;
                    font-size: 1.2rem;
                    font-weight: 600;
                    transform: translateX(-0.5rem);
                    text-anchor: end !important;
                }
                &:first-child, &:nth-child(3) {
                    //when the axis updates new ticks are added after the first ones which occupy spots 3 & 4
                    //so need to add this to the 3rd child when it exists temporarily
                    text {
                        transform: translateX(0.5rem);
                        text-anchor: start !important;
                    }
                }
            }

            line {
                stroke: none;
                fill: none;
            }

            path.domain {
                transform: scale(6, 1) translateX(-33%);
                stroke: $gray-400;
            }

        }

    }
}
