@import "../../../bower_components/bootstrap/scss/mixins/breakpoints";


/**
    Responsive Typography
 */


//$header-map: (
//    1: $h1-font-size,
//    2: $h2-font-size,
//    3: $h3-font-size,
//    4: $h4-font-size,
//    5: $h5-font-size,
//    6: $h6-font-size
//);
//
//@each $breakpoint in map-keys($grid-breakpoints) {
//
//    @if ($breakpoint != "xxs" and $breakpoint != "xl") {
//        @include media-breakpoint-up($breakpoint) {
//            @for $i from 1 through 6 {
//                h#{$i}, .h#{$i} {
//                    font-size: map-get($header-map, $i);
//                }
//                $header-map: map-merge($header-map, ($i: (map-get($header-map, $i) * $breakpoint-scale)))
//            }
//        }
//    }
//}



@include media-breakpoint-down(xxs) {
    html {
        font-size: 0.875rem;
    }
}

@include media-breakpoint-up(sm) {
    html {
        font-size: $font-size-base;
    }
}
