@mixin input-aurea-disabled-checkbox {
    background-color: $input-disabled-bg;

    & + label {
        cursor: not-allowed !important;

        &:before {
            background-color: $input-disabled-bg !important;
            border-color: $gray-500 !important;
            color: #AAA;
        }

        .dot {
            &:before {
                background: #aaaaaa;
            }
        }
    }

    &.box-style {
        & + label {
            border-color: $input-border-color !important;
            background-color: $input-disabled-bg !important;

            &:before {
                border-color: $gray-500 !important;
            }
            &:after {
                border-color: $input-border-color !important;
                //label bg color behind is is proper
                background: transparent !important;
            }
        }
    }

    &.button-style {
        & + label {
            border-color: $gray-200;
            background: transparent;
        }
        &:checked + label {
            background: $input-disabled-bg;
        }
    }
}


.input-aurea-container {

    clear: both;
    position: relative;
    // overflow: hidden;

    &.form-group {
        padding-top:20px;
        margin-bottom:10px;
    }

    .input-input {
        position: relative;
    }

    label.magic-label, .error {
        position:absolute;
        top: 0;
        left: $input-padding-x;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 300;
        white-space: nowrap;
    }
    .error {
        color: $form-feedback-invalid-color;
    }
    input.is-invalid {
        padding-right: $input-padding-x + 0.6rem;
    }

    label.magic-label {
        color: $md-gray;
        margin-bottom: 0;
        margin-top: 0;
        transition:opacity linear 0.2s, margin linear 0.2s;
        font-weight: $font-weight-bold;
        z-index: 3;

        &.empty {
            margin-top: 20px;
            opacity:0;
        }
        &.filled {
            opacity:1;
        }

        //&.place-label {
        //    &.empty {
        //        opacity: 1;
        //    }
        //    & + .input-input {
        //        input {
        //            &::placeholder {
        //                transform: translateY(0.3rem);
        //            }
        //        }
        //    }
        //}

    }
    &.has-error label.magic-label {
        transition: none;
        margin-top: 0;
        opacity: 0;
    }
    &.error-bottom {
        display: flex;
        flex-direction: column;
        & > * {
            flex-grow: 1;
        }
        .error {
            @extend .order-last;
            text-transform: none;
            position: relative;
            top: 0;
            padding-top: 5px;
            white-space: normal;
            line-height: 1rem;
        }

        label.magic-label:not(.empty) {
            opacity: 1;
            margin: 0;
        }
        &.has-error {
            label.magic-label {
                color: $form-feedback-invalid-color;
            }
        }
    }



    //force label to always be visible
    &.fixed-label, .fixed-label & {
        label.magic-label {
            transition: none;
            opacity: 1 !important;
            margin-top: 0 !important;
        }
        input {
            &::placeholder {
                color: transparent;
            }
        }
        .select2-container {
            .select2-selection__placeholder {
                color: transparent;
            }
        }
    }
    &.no-label {
        label.magic-label {
            display: none;
        }
    }

    label.magic-label + .input-input {
        .input-group {
            .input-group-prepend .input-group-text {
                background: none;
                padding-right: 5px;
                padding-left: 15px;
            }

            .input-group-prepend + .form-control {
                border-left: 0;
                padding-left: 0;
            }
        }
    }

    input {
        &::placeholder {
            top:5px;
            margin-top:5px;
        }
    }

    input[type=radio], input[type=checkbox] {
        display: inline-block;
        position: absolute;
        opacity: 0;

        & + label {
            position: relative;
            //padding regulates width space of check/radio img
            padding-left: 35px;
            margin-bottom: 0;
            min-height: 12px;

            line-height: 1.5rem;
            font-weight:300;
            cursor: pointer;


            &:before {
                @extend %wbi:before;

                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid $gray-500;
                text-align: center;
                content: '';
            }
            &:hover:before {
                background-color: rgbaMorph(lighten($primary, 30%), #FFF, 0.3);
                border-color: $primary;
            }

        }
        &:focus + label {
            &:before {
                box-shadow: $input-focus-box-shadow;
                border-color: $primary;
            }
        }

        &.box-style {
            & + label {
                padding: 8px 10px 8px 55px;
                border:1px solid $input-border-color;
                border-radius: $input-border-radius;
                width: 100%;
                &:hover {
                    border-color: $primary;
                    background-color: rgbaMorph(lighten($primary, 40%), #FFF, 0.1) !important;

                    &:after {
                        fixMapping: 1;
                        border-color: $primary;
                        background: rgbaMorph(lighten($primary, 30%), lighten($primary,  40%), .3) !important;
                    }
                }
                &:before, .dot {
                    top: 50%;
                    transform: translate(-50%, -50%);
                    left: calc(45px / 2);
                    z-index: 1;
                }
                &:before {
                    background: white;
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 45px;
                    height: 100%;
                    background: $input-disabled-bg;
                    border-radius: ($input-border-radius*0.9) 0 0 ($input-border-radius*0.9);
                    border-right:1px solid $input-border-color;
                    border-color: $input-border-color;
                }
            }
            &:focus + label {
                box-shadow: $input-focus-box-shadow;
                border-color: $primary;
                &:before {
                    box-shadow: none;
                }
            }
            &:checked + label{
                border-color: $primary;
                &:before {
                    box-shadow: none;
                }
                &:after {
                    fixMapping: 1;
                    border-color: $primary;
                    background: rgbaMorph(lighten($primary, 30%), #FFF, .3);
                }
            }
            &:checked + label {
                //background: transparent;
            }

        }

        &.right {
            & + label {
                padding-left: 0;
                padding-right: 35px;

                &:before, .dot {
                    left: auto;
                    right: 2px;
                }
            }
            &.box-style {
                & + label {
                    padding-left: 20px;
                    padding-right: 55px;

                    &:after {
                        left: auto;
                        right: 0;
                        border-left-width: 1px;
                        border-left-style: solid;
                        border-right: 0;
                        border-radius: 0 ($input-border-radius*0.9) ($input-border-radius*0.9) 0;
                    }
                }
            }
        }

        &.button-style {
            & + label {
                display: block;
                margin: auto;
                padding: 30px 20px;
                width: 100%;
                max-width: 200px;
                border: 1px solid $primary;
                border-radius: $input-border-radius;

                text-align: center;
                line-height: initial;

                &:hover {
                    background-color: rgbaMorph(lighten($primary, 40%), #FFF, 0.1);
                }
                &:before {
                    display: none;
                }
                .dot {
                    display: none !important;
                }
            }
            &:focus + label {
                fixMapping: 1;
                box-shadow: $input-focus-box-shadow;
                background: $gray-100;
            }
            &:checked + label {
                border-color: $primary;
                background-color: rgbaMorph(lighten($primary, 40%), #FFF, 0.1);
            }
        }


    }

    input[type=radio] {
        & + label {
            &:before {
                padding: 0;
                border-radius: 50%;
                line-height: 1.5em;
                font-size: 15px;
            }
            &:hover:before {
            }
        }
        &:focus + label {
            &:before {
            }
        }
        &:checked + label {
            &:before {
                border-color: $primary;
                color: $primary;
                //content: '•';
            }
        }

        & + label {
            .dot {
                display: none;
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;

                z-index: 2;
                &:before {
                    display: block;
                    margin: 4px;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    background: $primary;
                    content: '';
                }
            }
        }
        &:checked + label {
            .dot {
                display: block;
            }
        }

        &.box-style {
            & + label {
                &:before {
                    line-height: 1.45em;
                    font-size: 12px;
                }
                &:hover:before {
                    background: white;
                }
            }
        }
    }

    input[type=checkbox] {

        & + label {
            &:before {
                padding: 0.1rem 0;
                border-radius: $border-radius/1.5;
                font-size: 0.85rem;
                line-height: 1rem;
            }
            &:hover:before {
            }
        }
        &:focus + label {
            &:before {
            }
        }
        &:checked + label {
            @extend %wbi-check;
            &:before {
                background-color: $primary;
                border-color: $primary;
                color: white;
            }
            &:hover:before {
                color: $gray-500;
                background-color: rgbaMorph(lighten($primary, 30%), #FFF, 0.3);
            }
        }

        &.box-style {
            & + label {
                &:hover:before {
                    background: white;
                }
            }
            &:focus + label {
                &:before {
                    background: white;
                }
            }
            &:checked:not(:disabled):not(.disabled) + label {
                &:before {
                    background: $primary;
                }
                &:hover:before {
                    color: $white;
                }
            }
            &:focus:checked + label {
                &:before {
                    border-color: $primary;
                }
            }
        }

        &.slider-style {
            & + label {
                padding-left: 50px;
                &:before {
                    transition: background-color .4s;
                    content: '';
                    height: 25px;
                    width: 40px;
                    border: 0;
                    border-radius: 100px;
                    background: $md-gray;
                }
                &:after {
                    transition: left .4s;
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    height: 21px;
                    width: 21px;
                    border: 2px solid $lt-gray;
                    background: white;
                    border-radius: 100px;
                }
                &:hover:after {
                    background: $lt-gray;
                }
            }
            &:focus + label {
                &:after {
                    background: $gray-100;
                    box-shadow: inset 0 0 0 2px lighten($primary, 20%);
                }
            }
            &:checked + label {
                &:before {
                    background: $primary;
                }
                &:after {
                    left: 17px;
                }
            }
        }
    }

    fieldset[disabled] & {
        input[type=checkbox], input[type=radio] {
            @include input-aurea-disabled-checkbox;
        }
    }
    input[type=checkbox][disabled], input[type=radio][disabled] {
        @include input-aurea-disabled-checkbox;
    }

    &.form-group.input-radio, &.form-group.input-checkbox {
        margin-bottom: 0;

        .error {
            left: 3px;
            white-space: nowrap;
        }
    }

    &.open-input {
        min-width: 100px;
        display: inline-block;
        margin-bottom: 20px;

        line-height: 1.3;
        font-size: $h1-font-size;

        &.large {
            font-size: 72px;
        }

        &.form-group {
            padding-top:0;
        }
        &.has-error {

            .input-group {
                border-color: $form-feedback-invalid-color;
                .input-group-text {
                    color: $form-feedback-invalid-color;
                }
            }
            input {
                color: $form-feedback-invalid-color;
                border-color: $form-feedback-invalid-color;
            }
            .percent-marker {
                color: $form-feedback-invalid-color;
            }
            .select2 {
                .select2-selection {
                    border-color: $form-feedback-invalid-color;
                }
            }
        }

        .magic-label {
            display: none;
        }

        .input-group {
            border-bottom: 1px solid $primary;
            //used for currency
            .input-group-text {
                padding: 0 5px;
                border:none;
                background: transparent;
                color: $primary;
                font-size: inherit;
                font-weight: 200;
                line-height: inherit;
            }

            input {
                border-bottom: none;
            }
        }

        span.percent-marker {
            font-weight: 200;
            color: $primary;
        }

        .input-input {
            position: static;
        }

        &.input-percent {
            input {
                min-width: 85px;
                max-width: 85px;
                text-align: right;
                padding: 0;
            }
            input[currency-decimal="true"] {
                max-width: 140px;
            }
        }

        input {
            border:0;
            border-radius: 0;
            border-bottom: 1px solid $primary;
            box-shadow: none;
            margin:0;
            padding:0 10px;
            height:auto;
            display: inline;
            background: transparent;

            line-height: inherit;
            font-size: inherit;
            font-weight: 200;
            color: $primary;

            &:not(.show-placeholder) {
                &::placeholder {
                    color: transparent;
                }
            }
            &::placeholder {
                font-size: 22px;
                line-height: 42px;
                text-align: center;
            }
        }
        .inline-placeholder {
            padding: 0 11px;
            color:transparent;
            white-space: pre;
        }
        .error {
            transform: translate(-50%);
            width: auto;
            left: 50%;
            top:inherit;
            bottom:-20px;

            white-space: nowrap;
        }

        .select2 {
            min-width: 200px;
            .select2-selection--single {
                justify-content: center;

                border-width: 0 0 1px 0;
                border-radius: 0;
                border-color: $primary;

                height: auto;
                line-height: inherit;
                font-size: inherit;

                .select2-selection__rendered {
                    color: $primary;
                    font-weight: 200;

                    &:before { //keep spacing in case placeholder is empty
                        display: inline-block;
                        content: "\0a";
                        width: 0;
                    }

                    &:before, .select2-selection__placeholder {
                        font-size: 2rem;
                        line-height: initial;
                    }
                }
            }
            &.select2-container--disabled {
                .select2-selection {
                    background-color: $gray-200;
                }
                .select2-selection--single {
                    .select2-selection__rendered {
                        color: $text-muted;
                    }
                }
            }
        }
    }

    &.open-input {
        //extra adjustments

        &.currency-inline {
            min-width: 110px;

            .inline-placeholder {
                padding-left: 40px;
            }
            &.large {
                .inline-placeholder {
                    padding-left: 53px;
                }
            }
        }
        &.percent-inline {
            input {
                min-width: 0;
                max-width: none;
                padding-right: 40px;
            }
            .percent-marker {
                position: absolute;
                right: 0;
                //width: 0;
                //float: right;
            }
            .inline-placeholder {
                padding: 0 40px 0 0;
            }
        }
        &:not(.currency-inline):not(.percent-inline):not(.input-fluid) {
            min-width: 120px;
            input {
                //text-align: center;
            }
        }

        &.fake-line {
            &:before {
                content: "";
                border-bottom: 1px solid #4ba9d6;
                width: 300px;
                max-width: 95vw;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
            }
            &.large {
                &:before {
                    width: 400px;
                }
            }
            &[inline]:before {
                bottom: -1px;
            }
            &.has-error:before {
                border-color: $form-feedback-invalid-color;
            }
        }

        &.inline-fluid {
            text-align: left;

            input {
                position: absolute;
            }
            .input-group {
                position: absolute;
                input {
                    position: relative;
                }
            }
        }
        //&.inline-input-box {
        //    display: inline-block;
        //    position: relative;
        //    text-align: left;
        //
        //    //hidden element that keeps track of how wide the input text is
        //    .input-group, input {
        //        position: absolute;
        //        width:100%;
        //    }
        //}
    }

    .input-money {
        display: flex;
        align-items: center;

        &:before {
            position: absolute;
            left: 0;
            margin-top: -1px; //odd pixel rounding issue
            content: '$';
            padding-left: $input-padding-x;
            .has-error & {
                color: $form-feedback-invalid-color;
            }
        }
        input {
            padding-left: $input-padding-x * 1.6;
        }
    }

    &.input-dark {
        .select2 {
            .select2-selection {
                .select2-selection__rendered {
                    @extend .text-light;
                }
            }
        }
    }
}


.select2-container--bootstrap4 {
    .select2-dropdown.open-input {
        margin-top: -10px;
        border: 2px solid $lt-gray;
        border-radius: $input-border-radius*0.75;
        box-shadow: none;
        font-size: $h4-font-size;
        //text-transform: lowercase;

        .select2-results__option {
            padding: 2px 6px;
            font-size: inherit;
        }
    }
}
