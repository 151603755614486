.marketing-top-performer {
    section.marquee-header {
        height: auto;
        padding-bottom: 0;
        overflow: visible;
        background: #398775;

        img {
            width: 200px;
        }
    }

    section {
        .icon {
            img {
                width: 160px;
                @include media-breakpoint-down(xs) {
                    width: 100px;
                }
            }
        }
    }

    .returns-graph {
        $public-marketing-graph-axis-margin: 7.6rem;

        .bg-2y {
            background: $primary;
        }
        .bg-3y {
            background: #81d9d7;
        }

        .head {
            text-transform: uppercase;

            .index {
                display: inline-block;
                height: 10px;
                width: 10px;
            }
        }
        .graph-box {
            position: relative;
            min-height: 600px;

            .graph-axis {
                position: absolute;
                width: calc(100% - #{$public-marketing-graph-axis-margin});
                height: 100%;
                margin-left: $public-marketing-graph-axis-margin;
                div {
                    flex-basis: 100%;
                    position: relative;

                    border-right: 1px solid $gray-800;
                    &:first-child:before {
                        display: block;
                        content: "";
                        position: absolute;
                        height: 100%;
                        left: -5px;
                        border-left: 1px solid $gray-800;
                    }

                    span {
                        font-size: smaller;
                        position: absolute;
                        right: 5px;
                        text-align: right;
                    }
                    @include media-breakpoint-down(xs) {
                        &:not(:last-child) {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }

            .graph-data {
                position: relative;
                .data-item {
                    align-items: center;
                    margin-bottom: spacer(3);

                    .name {
                        width: $public-marketing-graph-axis-margin;
                        margin-right: 10px;
                        text-align: right;
                        line-height: 1;
                        font-weight: 400;
                    }
                    .bars {
                        flex-grow: 1;
                        .bar {
                            margin: spacer(1) 0;
                            height: 10px;
                            max-width: 0;
                            transition: max-width 2000ms ease-in-out;
                            //    773px is max
                        }
                    }
                }
            }

        }

    }

    .disclaimer {
        color: $gray-500;
    }
}
