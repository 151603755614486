.remove-footer-margin {
    //used for wrapping content above footer that doesn't need the margin
    margin-bottom: -100px;
    @include media-breakpoint-down(sm) {
        margin-bottom: -75px;
    }
}

.footer {
    position: relative;
    z-index: $z-index-footer;

    margin-top: 100px;
    @include media-breakpoint-down(sm) {
        margin-top: 75px;
    }

    background-image: url("/common/assets/images/aurea/footer/bg-arrows.svg");
    background-color: #f8f9f9;
    background-position: center -6px;
    padding:0;

    img {
        width:100%;
        max-width: 130px;
    }


    .top {
        background-color: #FFFFFF;
        padding: 15px 0;
        text-align: center;
        border-top: 1px solid $lt-gray;
        .logo {
            img {
                height: 56px;
                width: 56px;
            }
        }
    }
    .bottom {
        padding-top: 30px;
        padding-bottom: 20px;

        .bottom-body {
            margin:auto;
            margin-bottom: 60px;

            @include media-breakpoint-down(xxs) {
                & > [class^="col-"],
                & > [class*=" col-"] {
                    padding: 0;
                }
            }
        }

        &, p {
            font-size : 0.875rem;
            line-height: 1.25rem;
        }

        .content-left {
            margin-top:20px;

            ul {
                margin-bottom: 0;
            }

            p {
                margin:0;
            }

            h4 {
                margin:0 0 15px 0;
            }
        }

        .site-map ul {
            padding:0;
            list-style: none;
        }

        .social-icons {
            text-align: center;

            .social-icon {
                display:inline-block;
                padding:9px 9px 9px 9px;
                border-radius: 100px;
                box-shadow: 0 0 0.3px 1px #4e585c;
                font-size: 22px;
                line-height:0.6;
                color: $dk-gray;

                & * {
                    color: $dk-gray;
                }
            }
        }

        .disclaimer {

            p {
                margin-bottom: 15px;

                &:first-child {
                    margin-top:0;
                }
            }

            a.mixpanel-link {
                display:inline-block;
                margin-top:30px;

                p {
                    width:125px;
                }
            }

        }
    }
}
