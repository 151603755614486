/**
 *  Form related items - bs4 overwrites and custom
 */

.form-control {
    &:disabled, &.disabled {
        cursor: not-allowed;
    }
}
.form-control-lg, .btn-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    font-weight: $font-weight-lg;
}

.form-group {
    &.password {
        .input-group-text:hover {
            color: $primary;
        }
    }
}

input, textarea {
    font-weight: inherit;
    &:hover:not(:disabled):not(.disabled):not(.is-invalid) {
        border-color: $input-focus-border-color;
        background-color: rgba($primary, 0.10);
    }
    &.is-invalid:hover:not(:disabled):not(.disabled) {
        background-color: rgba($form-feedback-invalid-color, 0.10);
    }
}


/**
 * Fix spacing issue with select and add chevron dropdown arrow
 */
.select2-container--bootstrap4 {

    .select2-selection {
        transition: $input-transition;
        background-color: transparent;
    }

    &.select2-container--focus:not(.select2-container--open) {
        .select2-selection {
            box-shadow: $input-focus-box-shadow;
        }
    }
    &.select2-container--open {
        .select2-selection {
            border-color: $input-border-color;
            background-color: $input-disabled-bg;
        }
    }

    //enable only if we want to keep the shadow border when select open
    .select2-dropdown {
        box-shadow: $input-focus-box-shadow;

        .select2-results {
            .select2-results__options {
                padding: $input-padding-y 0;

                .select2-results__option {
                    padding-left: 1.6rem;
                    padding-top: 0;
                    padding-bottom: 0;

                    &[aria-selected=true] {
                        position: relative;

                        &:before {
                            @extend %wbi:before;
                            @extend %wbi-check:before;
                            position: absolute;
                            left: 0.8rem;
                            top: 50%;
                            transform: translate(-50%, -50%);

                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }

        &.select2-dropdown--above, &.select2-dropdown--below {
            border-style: solid; //override border-x:none
            border-radius: $border-radius;
            border-width: $input-border-width;
        }
        &.select2-dropdown--above {
            border-top-left-radius: $input-border-radius * 0.9;
            border-top-right-radius: $input-border-radius * 0.9;
        }
        &.select2-dropdown--below {
            border-bottom-left-radius: $input-border-radius * 0.9;
            border-bottom-right-radius: $input-border-radius * 0.9;
        }
    }
    &.has-error {
        .select2-dropdown {
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
        }
    }


    .select2-selection--single {
        text-align: start;

        .select2-selection__rendered {
            //padding-right: $s2bs-padding-base-horizontal;
            //flex-grow: 1;
        }

        .select2-selection__arrow {
            right: (($s2bs-padding-base-horizontal + $s2bs-caret-padding)/2) - 0.3rem/2;

            b {
                margin-top: -0.4rem;
                width: 0.80rem;
                height: 0.80rem;

                border-width: 0.2rem 0.2rem 0 0 !important;
                border-color: $input-placeholder-color !important;
                transform: rotate(135deg);
            }
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow b {
                margin-top: 0.4rem;
                border-width: 0 0 0.2rem 0.2rem !important;
            }
        }
    }

    .select2-selection--multiple {
        text-align: start;

        .select2-search--inline {
            .select2-search__field {
                color: inherit;
                background-color: transparent !important;
            }
        }

        .select2-selection__choice {
            color: inherit;
        }
    }

    .select2-selection__clear {
        margin: 0 (-$s2bs-padding-base-horizontal/2) 0 0;
        padding: 0;
        width: $s2bs-padding-base-horizontal;
        text-align: center;

        &:hover {
            color: $primary;
        }

    }

    &.select2-container--disabled {
        pointer-events: none;
    }

}
select:not(.is-invalid) + .select2-container--bootstrap4 {
    &:not(.select2-container--open):hover {
        .select2-selection {
            border-color: $input-focus-border-color;
            background-color: rgba($primary, 0.10);

        }
    }
}
select.is-invalid + .select2-container--bootstrap4 {
    &:not(.select2-container--open):not(.select2-container--disabled) .select2-selection, .select2-selection {
        border-color: $form-feedback-invalid-color;
    }
    &:not(.select2-container--open):hover {
        .select2-selection {
            background-color: rgba($form-feedback-invalid-color, 0.10);
        }
    }
    &.select2-container--focus:not(.select2-container--open) {
        .select2-selection {
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
        }
    }
}


.has-error {
    input, textarea {
        //bs4 no longer does this on it's own
        border-color: $form-feedback-invalid-color;
    }
}
.input-group-text {
    font-weight: 300;

    transition: border-color ease-in-out .15s;
    &:focus {
        //border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
        outline: none;
    }
    .has-error & {
        //border-color: $form-feedback-invalid-color;
    }
}

button:not(:disabled):not(.disabled) {
    cursor: pointer;
}



.form-dark {
    .form-control {
        color: $white;
        &:not(.is-invalid):not(:disabled):not(.disabled) {
            border-color: $primary;
        }
    }
    .input-group-text {
        color: $white;
    }
    :not(.has-error) {
        .input-group-text {
            border-color: transparent;
            //border-color: $primary;
        }
    }

    .select2-container--bootstrap4 {

        &:not(.select2-container--open):not(.select2-container--disabled) {
            .select2-selection {
                border-color: $primary;
            }
        }

        .select2-selection {
            .select2-selection__rendered {
                color: $white;
                padding-right: 10px;
                margin-right: -10px;
            }
        }
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: $white !important;
                }
            }
        }
    }

    table.table {
        thead {
            .sortable {
                .sort-indicator {
                    &:before, &:after {
                        filter: invert(1);
                    }
                }
            }
        }
    }

}
