/**
    Setting default behaviors
 */

html, body {
    -webkit-font-smoothing: antialiased;
}

a {
    font-weight: $link-font-weight;
    transition: color .5s, background-color .5s, border-color .5s;

    &[disabled], &:disabled, &.disabled {
        color: $btn-link-disabled-color;
        cursor: not-allowed;
        //pointer-events: none; //prevents popovers
    }

    &[ng-repeat] {
        transition: none;
    }
}
.btn, .btn-link {
    &[disabled], &:disabled, &.disabled,
    fieldset[disabled] & {
        &:hover, &:focus, &.focus {
            cursor: not-allowed;
        }
    }
}
.btn {
    //padding: ($btn-padding-y + 0.1) $btn-padding-x ($btn-padding-y - 0.1);
}




//custom
.note {
    color: $md-gray;
}


.label-up, %label-up {
    color: $md-gray;
    text-transform: uppercase;
    line-height: 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;

    .wbi {
        font-size: 0.8rem;
    }
    .popover {
        text-transform: none;
    }
}
span.label-up, span%label-up {
    display: inline-block;
}

.preload-image {
    display: none !important;
}


.ng-animate-disabled {
    // Use this for transitions
    &.ng-enter,
    &.ng-leave,
    &.ng-animate {
        -webkit-transition: none !important;
        transition: none !important;
    }
    // Use this for keyframe animations
    &.ng-animate {
        -webkit-animation: none 0s;
        animation: none 0s;
    }
}


#buorg {
    background: $warning;
    border-top: none;
    .buorg-buttons {
        #buorgul, #buorgpermanent {
            background: $primary;
            border: 2px solid $primary;
            box-shadow: none;
        }
        #buorgig {
            background: transparent;
            border: 2px solid white;
            box-shadow: none;
        }
    }
}
#browser-update-notice {
    transition: max-height 1000ms;
    max-height: 0;
    overflow: hidden;
    .container {
        max-width: 800px;

        #browser-update-browser-icon {
            height: 20px;
            width: 20px;
            vertical-align: top;
        }
    }
}
