.mp-page {
    @include media-breakpoint-up(md) {
        .container {
            max-width: breakpoint-max(md) !important;
        }
    }

    section {
        padding-top: spacer(head);
        padding-bottom: spacer(head);

        h1, h2, h3 {
            font-weight: 600;
        }

    }

    section.aum-block {
        //on .mp-managed-portfolios && .mp-investing-details

        .slider {
            height: 32px;
            border-left: 1px solid $gray-300;
            border-right: 1px solid $gray-300;

            .slider-track {
                height: 1px;
                margin: 0;
                border: 0;
                background: $gray-300;
                box-shadow: none;
            }

            .slider-handle:not(.hide) {
                @extend .flex-center;

                height: 32px;
                width: 32px;
                margin-left: -16px;
                border-radius: 50%;
                border-width: 0px;
                background: rgba($primary, 1);
                box-shadow: none;

                &:hover, &:focus {
                    box-shadow: 0 0 2px 3px lighten($primary, 20%);
                }
            }
        }

        .aurea-accordion {
            [uib-accordion-group] {
                border: none;

                h5 {
                    a {
                        display: inline-block;
                        padding: spacer(2) 0 0 0;
                        font-size: $font-size-base;
                    }
                }
            }
        }
    }
}

.mp-managed-portfolios {
    .index-header {
        h1 {
            font-weight: 800;
        }
    }

    section.calculator-block {
        position: relative;
        background-color: #DEF4FF;
        text-align: center;

        .input-group {
            position: relative;
            display: block;

            > .input-aurea-container {

                margin-bottom: 1.3rem;

                .error {
                    top: auto;
                    bottom: -5px;
                    transform: translateY(100%);
                    //bottom: calc(#{$input-height} + 3px);
                    line-height: 0.8rem;
                    white-space: normal;
                }

                &.has-error {
                    .magic-label {
                        display: block !important;
                        opacity: 1;
                    }
                }

            }

            .form-control, select, .select2-selection {
                &:not(:hover):not(:focus) {
                    width: 100%;
                    background-color: $white;
                }
                &:hover, &:focus {
                    background-color: rgba($white, 0.6);
                }

            }

            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: center;

                > .input-aurea-container {

                    flex-basis: calc(33% - 20px);

                    &:not(:last-child) {
                        .form-control, .select2-selection, .btn {
                            @include border-right-radius(0);
                        }
                    }
                    &:not(:first-child) {
                        .form-control, .select2-selection, .btn {
                            @include border-left-radius(0);
                        }
                    }

                    &.input-select {
                        margin-right: -1rem;
                        .select2-selection {
                            padding-right: 4rem;
                            .select2-selection__arrow {
                                right: 2rem;
                            }
                        }
                    }

                    &.input-button {
                        flex-basis: 0;
                        flex-grow: 1;
                        .btn {
                            border-radius: $btn-border-radius !important;
                        }
                    }

                }
            }
        }
    }

    section.vision-precision {
        .img-col {
            align-self: stretch;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    section.investing-goals {
        background-color: lighten($teal, 50%);
    }

}


.mp-personalization {
    .graphic {
        .lines {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            opacity: .3;

            svg {
                height: 100%;
                width: 100%;
                transform: scale(6) translateY(-1px);

                animation-name: fadeOut;
                animation-duration: 1.5s;
                animation-delay: 2s;
                animation-fill-mode: both;

                .line-02 {
                    animation-name: rotate-02;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-out;
                    animation-delay: .2s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }

                .line-03 {
                    animation-name: rotate-03;
                    animation-duration: 2.2s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-delay: .3s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }

                .line-04 {
                    animation-name: rotate-04;
                    animation-duration: 2.4s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-delay: .4s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }

                .line-05 {
                    animation-name: rotate-05;
                    animation-duration: 2.6s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-delay: .5s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }

                .line-06 {
                    animation-name: rotate-06;
                    animation-duration: 2.8s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-delay: .6s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }

                .line-07 {
                    animation-name: rotate-07;
                    animation-duration: 2.8s;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-delay: .7s;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;

                }
            }
        }

        @keyframes rotate-02 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(15deg);
            }
            100% {
                transform: rotate(15deg);
            }
        }

        @keyframes rotate-03 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(60deg);
            }
            100% {
                transform: rotate(60deg);
            }

        }

        @keyframes rotate-04 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(75deg);
            }
            100% {
                transform: rotate(75deg);
            }

        }

        @keyframes rotate-05 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(105deg);
            }
            100% {
                transform: rotate(105deg);
            }

        }

        @keyframes rotate-06 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(150deg);
            }
            100% {
                transform: rotate(150deg);
            }

        }

        @keyframes rotate-07 {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(0deg);
            }
            60% {
                transform: rotate(165deg);
            }
            100% {
                transform: rotate(165deg);
            }
        }
    }
}

.mp-features {
    .graphic {
        .gear01, .gear02, .gear03 {
            position: absolute;
            top: 0;
            right: 0;
            height: calc(175px + 2.5vw + 2.5vh);
            max-height: 245px;

            svg {
                height: 100%;
            }
        }
        .gear01, .gear02 {
            svg {
                animation: animate-gears 5s linear infinite;
            }
        }
        .gear03 {
            svg {
                animation: animate-gears-inverse 5s linear infinite;
            }
        }

        .gear01 {
            transform: translateY(-50%);
        }
        .gear02 {
            top: auto;
            bottom: 0;
            transform: translateY(50%);
        }
        .gear03 {
            top: 50%;
            transform: translate(-50%, -50%);
        }

        @keyframes animate-gears {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
        @keyframes animate-gears-inverse {
            from {
                transform: rotate(360deg);
            }
            to {
                transform: rotate(0deg);
            }
        }

    }

    section.tax-strategies, section.move-money {
        h6 {
            font-weight: 600;
        }
    }
    section.tax-strategies, section.move-money, section.invest-your-way {
        .image {
            margin-bottom: spacer(3);
            img {
                max-height: 185px;
                object-fit: cover;
                width: 100%;
            }
        }
    }
    section.invest-your-way {
        .image {
            position: relative;
            h4 {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                padding: spacer(3) 0;
                text-align: center;
                color: $white;
                font-weight: 400;
                @include linear-gradient(180deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%);
            }
        }
    }
}

.mp-investment-strategy {
    .graphic {
        .chart {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            opacity: .3;

            svg {
                width: 100%;
                transform: scale(1.1);
            }

            .chart-line {
                stroke-dasharray: 5000;
                stroke-dashoffset: 5000;
                animation: dashLine 10s linear forwards;
            }

            .c1, .c2, .c3, .c4, .c5, .c6, .c7, .c8, .c9, .c10 {
                opacity: 0;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-direction: alternate;
                animation-name: cShowLine;
            }

            .c1 {
                animation-delay: .2s;
            }
            .c2 {
                animation-delay: .5s;
            }
            .c3 {
                animation-delay: .8s;
            }
            .c4 {
                animation-delay: 1.1s;
            }
            .c5 {
                animation-delay: 1.4s;
            }
            .c6 {
                animation-delay: 1.7s;
            }
            .c7 {
                animation-delay: 2s;
            }
            .c8 {
                animation-delay: 2.3s;
            }
            .c9 {
                animation-delay: 2.6s;
            }
            .c10 {
                animation-delay: 2.9s;
            }

        }

        @include media-breakpoint-down(xs) {
            .chart {
                svg {
                    transform: scale(2);
                }
            }
        }
        @include media-breakpoint-down(xxs) {
            .chart {
                svg {
                    transform: scale(2) rotate(-10deg);
                }
            }
        }

        @keyframes dashLine {
            to {
                stroke-dashoffset: 0;
            }
        }
        @keyframes cShowLine {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }


    }

    section.buy-hold {
        background-color: lighten($primary, 30%);
    }


    section.balanced-portfolios {
        position: relative;
        background-color: lighten($teal, 55%);

        &:before {
            content: "";
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            background-image: url(/assets/public/images/mp/scale.svg);
            background-position: center right;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.6;
            filter: brightness(0.8);
        }
        @include media-breakpoint-up(lg) {
            &:before {
                background-position: center right calc(40% - 200px);
            }

        }
    }

}

.mp-investing-details {
    .graphic {
        .chart {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            opacity: .3;

            svg {
                height: 140%;
            }

            .c1 {
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-name: cEnlarge;
            }

        }

        @keyframes cEnlarge {
            0% {
                r: 0;
                opacity: 0;
            }
            100% {
                r: 200;
                opacity: 1;
            }
        }


    }

    section.fee-compare {
        table {
            text-align: center;

            thead {
                th {
                    color: $body-color;
                    font-weight: 400;
                    text-align: center;
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;

                        &:nth-child(n + 2) { //skip first column
                            min-width: 100px;
                            width: 20%;
                        }
                        &:first-child {
                            max-width: 125px;
                            text-align: left;
                        }
                        &:nth-child(2) {
                            background: rgba($primary, 0.15);
                            border-bottom-color: $gray-400;
                        }
                        &:nth-child(n + 3) { //skip first 2 columns
                            img[alt=yes] {
                                opacity: 0.5;
                            }
                        }
                        img {
                            transform: scale(0.8);
                        }
                    }
                }
            }
        }
    }
}

.mp-portfolio-plus {

    section.assets {
        .card.asset-item {
            flex-direction: row;
            margin-bottom: spacer(3);

            .card-img-left {
                max-width: 150px;
                @include border-left-radius($card-border-radius);

                object-fit: cover;
            }
            .card-body {
                .name {
                    min-width: 200px;
                }
            }
        }
    }
}

.mp-asset-modal {
    .header-image {
        position: absolute;
        height: 200px;
        width: 100%;
        object-fit: cover;
        margin-top: -1*$modal-inner-padding/2;
        margin-bottom: -3rem;
        @include border-top-radius($modal-content-border-radius);
    }
    .modal-body {

    }
}
