$toggle-bar-spacing: 6px;


header {

    .navbar {
        padding: 0;
        box-shadow: $nav-shadow;

        background-color: rgba(white, 0.95) !important;

        & {
            transition: opacity 0.5s, transform 0.5s;
            //disappearing nav on scroll animation
            &.ng-hide-add {
                transform: translateY(0);
            }
            &.ng-hide-add-active {
                transform: translateY(-$navigation-height);
            }

            &.ng-hide-remove {
                transform: translateY(-$navigation-height);
            }
            &.ng-hide-remove-active {
                transform: translateY(0);
            }
        }


        @include media-breakpoint-down(sm) {
            background: transparent linear-gradient(to bottom, rgba(white, 1) 0%, rgba(white, 1) 56px, rgba(white, 0.95) 56px, rgba(white, 0.95) 100%) !important;
        }

        font-weight: 400;

        &.space-holder {
            box-shadow: none;
            top: -1px;
        }

        .navbar-brand {
            margin-left: $navbar-padding-x;
            margin-right: auto;
            img {
                height: 2.25rem;
                margin-bottom: -0.625rem;
            }
        }


        .nav-link {
            padding: spacer(4) 0; //mainly logout by itself link
        }

        .navbar-toggler {

            padding: 4px 3px;
            margin: 0;
            margin-right: $navbar-padding-x;
            height: 34px;
            width: 38px;
            border: none;

            .bars {
                position: relative;
                display: block;
                height: 100%;
                width: 100%;

                .bar {
                    height: 3px;
                    width: 60%;
                    margin-left: 20%;
                    margin-top: 5px;
                    left: 0;
                    display: block;
                    position: absolute;
                    border-radius: $badge-pill-border-radius;
                    transition: 0.25s ease-in-out;
                    background: $dk-gray;
                    &:nth-child(1) {
                        top: 0;
                        transition-delay: 0.25s;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        top: $toggle-bar-spacing;
                        transition-delay: 0s;
                    }
                    &:nth-child(4) {
                        top: $toggle-bar-spacing * 2;
                        transition-delay: 0.25s;
                    }
                }
                &.opened .bar {
                    &:nth-of-type(1), &:nth-of-type(4) {
                        top: $toggle-bar-spacing;
                        opacity: 0;
                        transition-delay: 0s;
                    }
                    &:nth-of-type(2) {
                        transform: rotate(45deg);
                        transition-delay: 0.25s;
                    }
                    &:nth-of-type(3) {
                        transform: rotate(-45deg);
                        transition-delay: 0.25s;
                    }
                }
            }
        }

        .navbar-collapse {
            max-height: calc(100vh - 60px);

            .nav-link {

                &:hover, &:focus {
                    color: $dk-blue;
                    background: rgba($primary, 0.15);
                }
                &.active {
                    color: $dk-blue;
                    background: rgba($primary, 0.40);

                    &:hover, &:focus {
                        background: rgba($primary, 0.25);
                    }
                }
            }

            .right-links {
                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-width: 2px;
                    border-radius: $border-radius-lg;
                    width: 150px;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {
                &:not(.collapsing) {
                    overflow: auto;
                }
                transition: opacity 0.5s, height 0.7s;
                &.show-add {
                    opacity: 0.6;
                }
                &.show-add-active {
                    opacity: 1;
                }
                &.show-remove {
                    opacity: 1;
                }
                &.show-remove-active {
                    opacity: 0;
                }

                .nav-link {
                    padding-left: $navbar-padding-x;
                    padding-right: $navbar-padding-x;
                }

                .dropdown {
                    &.show {
                        z-index: 1; //make sure dropdown megamenu shadow is on top
                    }
                    &.nav-item.dropdown {
                        .dropdown-items {
                            .nav-link {
                                flex-basis: 100%;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    transition: max-height .5s;
                    background: transparent;
                    border: none;
                    overflow: hidden;

                    li.dropdown-item {
                        padding-left: $navbar-padding-x * 2;
                    }
                }

                .nav-link {
                    color: $primary;
                    font-weight: 400;
                    padding-top: spacer(4) / 2;
                    padding-bottom: spacer(4) / 2;

                    &.active {
                        color: $white;
                        background: $primary;

                        &:hover, &:focus {
                            background: rgba($primary, 0.75);
                        }
                    }
                }

                .right-links {
                    margin: $navbar-padding-y $navbar-padding-x;
                    .btn {
                        width: 200px;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                height: auto !important;

                .top-links {
                    align-items: center;

                    display: flex;
                    //margin-right: calc(0.5rem + 2.25rem + 1.5rem);


                    .dropdown-menu {
                        .nav-link {
                            border-radius: $border-radius-lg * 2;
                        }
                    }

                    & > .dropdown.show > .nav-link {
                        background-color: rgba(lighten($primary, 25%), 0.15);
                    }

                    & > .nav-link, & > .dropdown > .nav-link {
                        position: relative;
                        padding: spacer(4) $nav-link-padding-x;
                        margin: 0 0;

                        background: transparent;
                        &:focus, &:hover, .show & {
                            background-color: rgba(lighten($primary, 25%), 0.15);
                        }
                        //&:not(:hover):not(:focus) {
                        //}

                        &:focus {
                            outline: none;
                        }

                        &.active {
                            color: $dk-blue;

                            &:before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 100%;
                                left: 0;
                                bottom: -1px;
                                border-top: 4px solid $primary;
                            }
                        }
                    }

                }

                .dropdown {
                    &.nav-item.dropdown {
                        .dropdown-items {
                            .nav-link {
                                flex-basis: 33.3%;
                                padding-top: spacer(4) / 2;
                                padding-bottom: spacer(4) / 2;
                                margin-bottom: spacer(2);
                                text-align: center;

                            }
                        }
                    }
                }

                .right-links {
                    margin: 0 $navbar-padding-x;
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                margin-top: -1px;
                border-radius: 0;
                background-color: rgba(white, 0.95) !important;

                transition: all 0.4s ease-in-out;
                &.show-add {
                    display: block;
                    max-height: 0;
                    z-index: $zindex-dropdown + 1;
                }
                &.show-add-active {
                    max-height: 400px;
                }
                &.show-remove {
                    display: block;
                    max-height: 400px;
                    z-index: $zindex-dropdown - 1;
                }
                &.show-remove-active {
                    max-height: 0;
                }
                overflow: hidden;

                li.dropdown-item {
                    padding: 0 $dropdown-item-padding-x !important;

                    color: $body-color;
                    white-space: normal;

                    &:hover {
                        background: none;
                    }

                    .dropdown-info {
                        padding: spacer(3);
                        padding-top: spacer(4);
                        h4 {
                            line-height: 1rem;
                        }
                    }
                    .dropdown-items {
                        padding-top: spacer(4);

                        & > div {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        .dropdown-info {
                            border-bottom: 1px solid $border-color;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        .dropdown-info {
                            border-right: 1px solid $border-color;
                        }
                    }
                }
            }

            .dropdown-toggle {
                &:after {
                    //add thin chevron to right of items
                    @extend %wbi;
                    @extend %wbi-chevron-right:before;

                    transform: rotate(90deg);
                    margin-left: 0.5rem;
                    vertical-align: 0px;
                    font-size: 0.8rem;
                    border: none;

                    color: white;
                    text-shadow: 1.5px 0 $primary;

                }
            }

        }
    }
}
