.calculator-container {

    @include media-breakpoint-down(md) {
        .container {
            max-width: none;
        }
    }

    //********* Genteral Page Components **********

    .progress-box {
        position: relative;
        margin-top: -1px;
        margin-bottom: -1*spacer(4);
        padding-bottom: spacer(2);
        z-index: 1;
        overflow: hidden;

        //background: transparent linear-gradient(to right, transparent 0% 50%, rgba($primary, 0.40) 50% 100%);
        .progress {
            margin: auto;
            margin-top: -1*spacer(2);
            width: 80%;
            border-radius: $border-radius-sm;
            box-shadow: $box-shadow-sm;
            background: $white;

            height: spacer(4);
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            .progress {
                width: 100%;
            }
        }

    }

    .page-buttons {
        margin-top: spacer(3);
        padding-top: spacer(3);

        .btn {
            width: 100%;
        }
    }

    .page-entry {
        padding: spacer(head) $grid-gutter-width spacer(4);

        .page-title {
            margin-bottom: spacer(3);

            .label-up {
                margin-bottom: spacer(2);
                font-size: 1rem;
            }

            h1 {
                margin: 0;
            }
        }

        h1, .h2 {
            font-weight: 600;
        }

        .input-aurea-container {
            //margin-bottom: spacer(3);
        }

        input, select, .select2-selection {
            width: 100%;
        }

        .select2-selection {
            .select2-selection__arrow {
                right: 1.5rem;
            }
        }

        .page-buttons {
        }
    }

    .page-display {
        padding: spacer(head) $grid-gutter-width spacer(4);

        background: rgba($primary, 0.10);

        h2 {
            font-weight: 600;
        }

        .page-buttons {
            //@include media-breakpoint-down(sm) {
            //    display: block;
            //}
            //@include media-breakpoint-up(md) {
            //    display: none;
            //}
        }

    }

    .profile-box {
        text-align: center;

        .card {
            margin-bottom: spacer(3);

            .h1 {
                margin: 0;
                font-weight: 600;
            }
        }
    }



    //********* Specific Page Components **********

    .page-initial {
        .page-display {

            hr {
                margin-top: 18vh;
                margin-bottom: 18vh;

                border-color: $gray-400;
            }

            .profile-box {
                .card {
                    .card-body {
                        padding-left: spacer(2);
                        padding-right: spacer(2);
                    }
                }
            }

        }
    }

    .page-milestone {
        .page-entry {
            .page-input {
                .input-radio {
                    z-index: 1;

                    label {
                        display: flex;
                        align-items: center;
                        padding-top: $input-padding-y;
                        padding-bottom: $input-padding-y;
                        max-width: none;
                        min-height: $input-height;
                        text-align: left;
                        font-weight: bold;

                        line-height: $line-height-base;

                        img {
                            height: 1.8rem;
                            filter: invert(60%);
                            margin-top: -0.2rem;
                            margin-right: spacer(2);
                        }
                    }
                    input:checked + label {
                        color: $primary;
                        img {
                            filter: invert(17%) sepia(23%) saturate(7455%) hue-rotate(169deg) brightness(89%) contrast(86%);
                        }
                    }

                }

                @include media-breakpoint-up(md) {
                    .aurea-accordion {
                        display: none;
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin-left: $grid-gutter-width * -1;
                    margin-right: $grid-gutter-width * -1;

                    .form-group {
                        padding: 0;

                        input + label {
                            border-color: lighten($primary, 20%);
                            border-width: 1px 0 0 0;
                            border-radius: 0 !important;
                        }
                        input:not(:checked) + label {
                            box-shadow: 0 0 .4rem 0 rgba($black, .15);
                        }

                    }

                    .aurea-accordion {
                        margin-top: -2.5rem;

                        .wbi-close {
                            margin-right: 2rem;
                            margin-top: -0.7rem;
                        }

                        .card {
                            border: none;

                            .card-body {
                                background: rgba($primary, 0.10);
                                //background-color: rgbaMorph(lighten($primary, 30%), #FFF, 0.3);

                                img {
                                    min-height: 120px;
                                    max-width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
    //for displaying selected milestone on milestone and congratulations pages
    .page-display-milestone {
        padding-top: spacer(3);

        .milestone-image {
            position: relative;
            height: 300px;
            margin-bottom: 5rem;

            @include media-breakpoint-up(md) {
                margin-left: $grid-gutter-width * -1;
                margin-right: $grid-gutter-width * -1;
            }

            .logo {
                height: 100%;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .icon {
                position: absolute;

                bottom: 0%;
                left: 50%;
                transform: translate(-50%, 50%);
                background: transparent;

                border-radius: 50%;
                border: 2px solid white;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    background: white;
                    height: 7.5rem;
                    width: 7.5rem;
                    border-radius: 50%;

                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                }

                img {
                    height: 4.2rem;
                    width: 4.2rem;
                    filter: invert(17%) sepia(23%) saturate(7455%) hue-rotate(169deg) brightness(89%) contrast(86%);
                    //border-radius: 50%;
                    margin: 1.9rem;
                }
            }
        }
    }

    .page-preferences {

        .info-top {
            position: relative;
            //margin-bottom: -4rem;
            z-index: 1;

            .display-4 {
                height: 0;
                font-weight: 600;
            }
        }

        .graph {
            margin-left: $grid-gutter-width * -1;
            margin-right: $grid-gutter-width * -1;

            .disclaimer-open {
                position: absolute;
                margin-top: -2.7rem;
                margin-bottom: 1rem;
                width: 100%;
            }
        }

        .slider {
            height: 50px;

            .slider-track {
                height: 1px;
                margin: 0;
                border: 0;
                background: $gray-500;
            }

            .slider-handle:not(.hide) {
                @extend .flex-center;

                height: 50px;
                width: 50px;
                margin-left: -25px;
                border-radius: 0.875rem;
                border-width: 2px;
                background: rgba($primary, 0.4);
                box-shadow: 0 0 0 0.7px $gray-500;

                &:focus {
                    box-shadow: 0 0 2px 3px lighten($primary, 20%);
                }

                &:before {
                    content: "";
                    display: block;
                    z-index: -1;
                    height: 1rem;
                    width: 1rem;
                    background: $gray-500;
                    border-radius: 100px;
                }
            }
        }

        .page-display .page-buttons {
            display: none;
        }

        .user-motivation-card {
            margin-top: spacer(0);
        }

        @include media-breakpoint-down(sm) {
            .graph {
                .x.axis {
                    .domain {
                        display: none;
                    }
                }

                .disclaimer-open {
                    margin-top: -2.9rem;
                }
            }

            .page-entry {
                padding-bottom: spacer(3);

                background: rgba($primary, 0.10);

                input, select, .select2-selection, .input-input {
                    background: $white;
                }

                .page-buttons {
                    display: none;
                }
            }
            .page-display {
                padding-top: 0;

                .h2 {
                    font-weight: 600;
                }

                .user-motivation-card {
                    margin-top: spacer(4);
                    border: none;
                    background: transparent;
                    .card-body {
                        padding: 0;
                    }
                }

                .page-buttons {
                    background: white;
                    padding: spacer(head) $grid-gutter-width;
                    margin-top: spacer(3);
                    margin-left: -1*$grid-gutter-width;
                    margin-right: -1*$grid-gutter-width;
                    margin-bottom: -1*spacer(4);

                    display: block;
                }
            }
        }

        @include media-breakpoint-down(xxs) {
            .graph {
                .disclaimer-open {
                    position: relative;
                    margin-top: -2rem;
                }
            }
        }

    }

    .page-congratulations {
        .profile-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            .card {
                margin-top: spacer(3);
            }
        }

        #confetti-holder {
            position: absolute;
            left:0;
            top:0;

            z-index: 1;
        }

        button, input, .on-top {
            //must be on top of confetti
            position: relative;
            z-index: 2;
        }

        .page-entry {
            div[code-input] {
                @include media-breakpoint-up(md) {
                    .code-inputs-boxes {
                        margin-left: $grid-gutter-width / -2;
                        margin-right: $grid-gutter-width / -2;
                        input {
                            padding: 0;
                            margin: 0 0.2rem;
                        }
                    }
                }
            }
        }
        .page-display {
            .text-info-box {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            .page-display {
                background: white;

                .page-inner {
                    background: rgba($primary, 0.10);
                    overflow: hidden;

                    .milestone-image {
                        margin-bottom: 0;
                        height: 100px;
                        .icon {
                            display: none;
                        }
                    }
                    .text-info-box {
                        padding: $card-spacer-y $card-spacer-x;
                        text-align: left;

                        h2 {
                            margin: 0;
                        }

                        .profile-box {
                            display: block;
                            text-align: left;

                            .card {
                                border: none;
                                background: transparent;
                                .card-body {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
