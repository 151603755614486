@keyframes fadeInOut {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeInOut {
    animation-name: fadeInOut;
}






@keyframes bounceInFadeOut {
    0% {
        transform: scale3d(0.2, 0.2, 0.2);
    }

    30%, 50%, 70%, 90% {
        transform: scale3d(0.8, 0.8, 0.8);
    }
    40%, 60%, 80% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    60% {
        opacity: 1;
    }

    100% {
        visibility: hidden;
        transform: scale3d(0.5, 0.5, 0.5);
        opacity: 0;
    }
}

.bounceInFadeOut {
    animation-name: bounceInFadeOut;
}

@keyframes spinCW {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes spinCCW {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}

.spinCW {
    animation-name: spinCW;
}
.spinCCW {
    animation-name: spinCCW;
}
