/*********************************/
/******* STICKY FOOTER ***********/
/*********************************/
html, body {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
}
.module-content {
    flex: 1 0 auto;
}
footer {
    flex-shrink: 0;
}
