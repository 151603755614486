/** Privacy And Terms page styling **/

.privacy-content, .terms-content {
    margin-top: $header-margin;

    h1 {
        margin: 0;
    }

    li {
        line-height: 1.5;
        font-size: 0.9rem;
        margin-left: 10px;
    }

    p {
        line-height: 1.2;
        font-size: smaller;
        &.indent {
            margin-left: 30px;
        }
    }

    h4 {
        font-weight: 400;
    }
}
