.odometer.odometer-auto-theme {
    display: inline-block;
    position: relative;

    .odometer-digit {
        display: inline-block;
        position: relative;

        .odometer-digit-spacer {
            display: inline-block;
            visibility: hidden
        }

        .odometer-digit-inner {
            text-align: left;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }

        .odometer-ribbon {
            display: block;
        }

        .odometer-ribbon-inner {
            display: block;
        }
        .odometer-value {
            display: block;

            &.odometer-last-value {
                position: absolute;
            }
        }
    }

    &.odometer-animating-up {

        .odometer-ribbon-inner {
            transition: transform 2s;
        }

        &.odometer-animating .odometer-ribbon-inner {
            transform: translateY(-100%);
        }
    }

    &.odometer-animating-down {
        .odometer-ribbon-inner {
            transform: translateY(-100%);
        }

        &.odometer-animating .odometer-ribbon-inner {
            transition: transform 2s;
            transform: translateY(0)
        }
    }
}
